import React from "react";
import LandingPage from "./Components/LandingPage";
import AnalysisLandingPage from "./Components/AnalysisLandingPage";

import MathLandingPage from "./Components/MathLandingPage";

import NewProject from "./Components/NewProject";
import Importcsv from "./Components/Pages/Importcsv";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import Select from "./Components/Select";
import SelectSelexMath from "./Components/SelectSelexMath";
import Admin from "./Components/Admin/Admin";
import AdminHome from "./Components/Admin/AdminHome";
import FirstPage from "./Components/FirstPage";
import About from "./Components/About";
import { ProtectedRoute,AdminRoute } from "./Helpers/route";
import ForgotPassword from "./Components/Pages/ForgotPassword";
import ForgotPasswordAdmin from "./Components/Pages/ForgotPasswordAdmin";
import Help from "./Components/Help"
import GPT from "./Components/GPT";
import SelexMath from "./Components/SelexMath";
import SelexHelp from "./Components/SelexHelp";
import SelexAnalysis from "./Components/SelexAnalysis";



function App() {
  const token = localStorage.getItem("usertoken");
  const [admin, setAdmin] = React.useState(false);
  const [userToken, setUserToken] = React.useState("");
  const checkAdminRole = (value) => {
    setAdmin(value)
  };

  const handleNavigate =async (token)=>{
    setUserToken(token)
    localStorage.setItem("usertoken", token);
  }
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<FirstPage />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/select" element={<Select />} />
          <Route exact path="/selectselexmath" element={<SelectSelexMath />} />
          <Route exact path="/selexmath" element={<SelexMath />} />
          <Route path="/selexanalysis" element={<SelexAnalysis />} />



          <Route exact path="/login" element={<Login handleNavigate={handleNavigate} />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/forgotpassword/:id" element={<ForgotPassword />} />
          <Route exact path="/forgotpasswordadmin" element={<ForgotPasswordAdmin />} />
          <Route exact path="/forgotpasswordadmin/:id" element={<ForgotPasswordAdmin />} />
          <Route path="/admin" element={<Admin checkAdminRole={checkAdminRole} />} />
          <Route
            path="adminhome"
            element={
              <AdminRoute admin={admin}>
                <AdminHome />
              </AdminRoute>
            }
          />
          <Route element={<ProtectedRoute user={token || userToken} />}>
            <Route path="/home" element={<NewProject />} />
            <Route path="/newproject" element={<LandingPage />} />
            <Route path="/newmathproject" element={<MathLandingPage />} />
            <Route path="/newanalysisproject" element={<AnalysisLandingPage />} />

            <Route path="/importcsv" element={<Importcsv />} />
            <Route path="/help" element={<Help />} />
            <Route path="/selexmath-help" element={<SelexHelp />} />
            <Route path="/gpt" element={<GPT />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
