import {
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { useEffect, useState } from "react";

  import { useNavigate } from "react-router-dom";
  import { baseURL } from "../Config/config";
  
  import { Link } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { Paper } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import Projectcolors from "../Utils/Colors";
  
  import Selexlogo from "../images/de2.png";
  import matrix from "../images/matrix.jpg";
  import selexmathimg from "../images/SelexMath-2.png";
  import SubNavbar from "./CommonC/SubNavbar";
  import { Alert } from "@mui/material";
  import BgContainer2 from "./CommonC/BgContainer2";
  import Grow from '@mui/material/Grow';
  import './YourComponent.css';
  import SelexSolNavbar from "./CommonC/SelexSolNavbar";

  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 700,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    overflow: "scroll",
    display: "block",
  };
  const stylec = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 450,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    display: "block",
  };



  
  const SelectSelexMath = ({handleNavigate}) => {
    const navigate = useNavigate();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openc, setOpenc] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
      isOpen: false,
      vertical: "top",
      horizontal: "center",
    });
    const [checked, setChecked] = React.useState(false);
   
  
    const { vertical, horizontal, isOpen } = state;
  
    const handleOpenc = () => setOpenc(true);
    const handleClosec = () => setOpenc(false);
  
    const handleAuth = async () => {
      setLoading(true);
      const body = {
        username,
        password,
      };
  
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      try {
        let result = await fetch(`${baseURL}/login`, options);
        result = await result.json();
        if (result.token) {
          handleNavigate(result.token)
          navigate("/home");
          setLoading(false);
        }
        setLoading(false);
        handleSnackbar(true);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
  
      return;
    };
  
    const handleSnackbar = (value) => {
      setState({ ...state, isOpen: value });
    };

    React.useEffect(() => {
      setChecked(true);
    }, [])
  
    return (
      <div
        style={{
          height: window.screen.height,
        }}
      >
        <BgContainer2>
        <SelexSolNavbar />

  
          <br />
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              //flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              marginTop: "9%",
              marginLeft: "35%",
              height:'250px',
              width:'520px',
              padding:'20px'
            }}
          >
            <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold"}}>Select a SelexMath Solution : </Typography>
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              display: "flex",
              flex: 1,
              flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              //marginTop: "9%",
              //marginLeft: "35%",
              height:'150px',
              width:'450px',
              padding:'10px'
            }}
          >
            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"6%",
                position: "relative",
                height:'140px',
                width:'220px'

              }}
            >
              <div style={{ position: "absolute", left: "10px",alignItems:"center" }} className="zoom">
              <Link
                sx={{
                  fontSize: "11px",
                  marginTop: "2px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selexmath")}
              >
                <img
                  style={{
                    height: "70px",
                    width: "145px",
                    marginLeft:"11%",
                    marginTop: "7%",
                    objectFit: "contain",
                  }}
                  src={Selexlogo}
                />
                </Link>
                <Typography sx={{marginLeft:"12%",fontSize:"13px"}}>Mathematical Modeling</Typography>

              </div>
  
             
  
              
              
  
             
            </div>
            </Grow>
            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 20px 2px 2px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"3%",
                position: "relative",
                height:'140px',
                width:'240px'

              }}
            >
                              <div style={{ position: "absolute", left: "10px",alignItems:"center" }} className="zoom">

                  <Link
                sx={{
                  fontSize: "11px",
                  marginTop: "2px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selexanalysis")}
              >
                <img
                  style={{
                    height: "70px",
                    width: "145px",
                    marginLeft:"11%",
                    marginTop: "7%",
                    objectFit: "contain",
                  }}
                  src={matrix}
                />
                </Link>
                <Typography sx={{marginLeft:"12%",fontSize:"13px"}}>Reduced Order Modeling</Typography>

              </div>
              </div>
              </Grow>
          </div>
          </div>
  
          
        </BgContainer2>
      </div>
    );
  };
  
  export default SelectSelexMath;
  